import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import VmActionList from "../../components/shared-action-list";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_NORMAL, TD_LAST } from "../../constants/style";
import { t, use } from "i18next";

const SubjectIndexPage = observer(() => {
  const { rootStore, academicStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  // Determine is update or create
  const [targetDomain, setTargetDomain] = useState<any>();
  const [domainCode, setDomainCode] = useState<string>("");
  const [domainName, setDomainName] = useState<string>("");
  const [searchKey, setSearchKey] = useState<string>("");
  const [domainDescription, setDomainDescription] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const delayDebouncer = setTimeout(() => {
      academicStore.searchQualificationByCode(searchKey, PAGE_SIZE, 0);
      setPage(1);
    }, 800);
    return () => clearTimeout(delayDebouncer);
  }, [searchKey]);

  useEffect(() => {
    // academicStore.searchQualificationByCode(searchKey, PAGE_SIZE, 0);
    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0 ||
      academicStore.unitList.data != ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT)) {
      academicStore.getUnitList(0, 0);
    }
  }, []);

  const onActionDomain = () => {
    let req: any = {
      // id: 0,
      code: domainCode,
      description: domainDescription,
      name: domainName,
      collegeId: GetCollegeId(),
    }
    if (targetDomain) {
      req.id = targetDomain.id;
      academicStore.updateQualification(req).then(() => {
        rootStore.notify(t('DOMAIN') + t('UPDATED_B'), 'success');
        academicStore.searchQualificationByCode(searchKey, PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      academicStore.updateQualification(req).then(() => {
        rootStore.notify(t('DOMAIN') + t('CREATED_B'), 'success');
        academicStore.searchQualificationByCode(searchKey, PAGE_SIZE, (page - 1) * PAGE_SIZE);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = academicStore.qualiBySearchKey.data.find(s => s.id == id);
      setTargetDomain(target);
      setDomainCode(target.code);
      setDomainName(target.name);
      setDomainDescription(target.description);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setShowLinkModal(false);
    setTargetDomain(null);
    setDomainCode("");
    setDomainName("");
    setDomainDescription("");
  }

  const handleDeleteModal = (id: number) => {
    let target = academicStore.qualiBySearchKey.data.find(d => d.id == id);
    setTargetDomain(target);
    setShowDeleteModal(true);
  }

  const handleShowLinkModal = (id: number) => {
    let target = academicStore.qualiBySearchKey.data.find(s => s.id == id);
    setTargetDomain(target);
    academicStore.getUnitListByQualificationId(target.id, 0, 0);
    setShowLinkModal(true);
  }

  const onLinkDomainWithSubjects = (reqData: any) => {
    academicStore.editQualificationUnitRelation({
      qualificationId: targetDomain.id,
      unitIdList: reqData.map(r => r.id),
      /* Benchmark customised */
      // Below apply to return a list with subject compulsory only = true
      compulsoryIdList: reqData.filter(r => r.compulsory == true).map(r => r.id)
      // compulsoryIdList: reqData.map(r => r.id),
    }).then(() => {
      rootStore.notify(t('DOMAIN') + ' Link Units Success', 'success');
      academicStore.searchQualificationByCode(searchKey, PAGE_SIZE, (page - 1) * PAGE_SIZE);
      handleCloseModal();
    }).catch(() => {
      rootStore.notify(t('DOMAIN') + ' Link Units Failed. Please Contact Network Admin!', 'error');
    });
  }

  return (
    <ContentLayout
      pageName={t('DOMAIN') + t('MODULE_B')}
      pageHeading={t('DOMAIN')}
      breadCrumb={[
        { label: t('DOMAIN') + t('SETTING_B'), to: "/academic/domain-module" },
        { label: t('OVERVIEW'), to: "" }
      ]}
      buttonLabel={t('ADD_A') + t('DOMAIN')}
      onClickAction={() => handleShowModal()}
    >
      <TextField
        value={searchKey}
        label={'Search Qualification by Code'}
        variant="outlined"
        sx={{ width: 300, background: 'white', marginBottom: 2 }}
        onChange={(value) => setSearchKey(value.target.value)}
        // onKeyUp={handleKeyPress}
        size="small"
      />
      <VmTable
        loading={academicStore.loadingSubject}
        thead={["ID", t('DOMAIN') + t('CODE_B'), t('DOMAIN') + t('DESCRIPTION_B'), t('ACTION')]}
        page={page}
        paginationCount={academicStore.qualiBySearchKey.totalCount == 0 ? 1 : academicStore.qualiBySearchKey.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          academicStore.searchQualificationByCode(searchKey, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        tbody={academicStore.qualiBySearchKey.data.length > 0 &&
          academicStore.qualiBySearchKey.data.map((domain, index) => (
            <>
              <Box sx={{ marginY: 1 }} />
              <tr key={`domain_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{domain.code}</td>
                <td className={TD_NORMAL}>{domain.description}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(domain.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowLinkModal(domain.id)}
                  >
                    {t('LINK_SUBJECTS')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(domain.id)}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
            </>
          ))}
      />

      {/* Add/Update */}
      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetDomain ? t('UPDATE_A') + t('DOMAIN') : t('ADD_A') + t('DOMAIN')}
        buttonLabel={targetDomain ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetDomain ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionDomain}
        disabledCondition={domainCode === "" || domainDescription === ""}
        buttonLoading={academicStore.loading}
      >
        <TextField
          value={domainCode}
          label={t('DOMAIN') + t('CODE_B') + ' *'}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setDomainCode(value.target.value)}
        />
        <TextField
          value={domainName}
          label={t('NAME')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setDomainName(value.target.value)}
        />
        <TextField
          value={domainDescription}
          label={t('DESCRIPTION') + ' *'}
          variant="outlined"
          sx={{ width: '100%' }}
          rows={4}
          multiline
          onChange={(value) => setDomainDescription(value.target.value)}
        />
      </VmModal>

      {/* Delete */}
      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        buttonLoading={academicStore.loading}
        onClickConfirmedButton={() => academicStore.deleteQualification(targetDomain.id).then(() => {
          rootStore.notify(t('DOMAIN') + t('DELETED_B'), 'success');
          academicStore.searchQualificationByCode(searchKey, PAGE_SIZE, (page - 1) * PAGE_SIZE);
          handleCloseModal();
        }).catch(() => rootStore.notify('Delete failed, there are other records has linked to this qualification', 'error'))}
      >
        <Typography sx={{ marginBottom: 0 }}>{t('DELETE_CHECK')}: Qualification?</Typography>
        {/* <Typography color="red" variant="caption">
          {t('DELETE_INFO_DOMAIN')}
        </Typography> */}
      </VmModal>

      {/* Link */}
      <VmModal
        openModal={showLinkModal}
        onClose={() => setShowLinkModal(false)}
        title={t('LINK_DOMAIN_SUBJECT')}
        showButton={false}
        width={700}
      >
        {
          academicStore.loadingCompulosories || academicStore.loading
            ? <Box sx={{ textAlign: 'center', paddingY: 2 }}><CircularProgress /></Box>
            :
            <VmActionList
              list={academicStore.unitList.data}
              onClose={() => setShowLinkModal(false)}
              label="code"
              leftLabel={t('STEP_1_SELECT')}
              rightLabel={t('STEP_2_EDIT_SELECT')}
              buttonLabel={t('LINK_CONFIRM')}
              buttonLabelWithoutConfirm={t('LINK_NOW')}
              onClickConfirmedButton={onLinkDomainWithSubjects}
              addedRecord={academicStore.unitQualificationRelationList.data.length > 0 ? academicStore.unitQualificationRelationList.data : []}
            >
              {/* <div className="flex mt-2">
                <Switch checked={true} disabled size="small" />
                <Typography variant="caption">{t('TOGGLE_COMPULSORY_STATUS')}</Typography>
              </div> */}
            </VmActionList>
        }
      </VmModal>
    </ContentLayout>
  )
});

export default SubjectIndexPage;
